import { fetchUserContext } from '@features/context/thunks/fetchUserContext'
import { fetchContract } from '@features/contract/thunks/fetchContract'
import { fetchEntitledOrganization } from '@features/organization/thunks/fetchEntitledOrganization'
import { setGuestLoggedIn } from '@features/user/slice'
import guestIdentityService from '@foundation/apis/transaction/guestIdentity.service'
import { storageSessionHandler } from '@foundation/utils/storageUtil'
import { AppDispatch } from '@redux/store'
import { LoginIdentityResponse } from '@typesApp/account'

export const createUserIdentity = async (dispatch: AppDispatch) => {
  const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()
  if (!currentUser) {
    const guestResponse = await guestIdentityService.login({})
    const guestResponseData = guestResponse.data as LoginIdentityResponse
    dispatch(setGuestLoggedIn(guestResponseData))
    dispatch(fetchContract(guestResponseData))
    dispatch(fetchEntitledOrganization(guestResponseData))
    dispatch(fetchUserContext(guestResponseData))
  }
}
