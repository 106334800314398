import { AssociatedProduct, Attachment, IProduct, ProductRightColumnProps, ProductType } from '@typesApp/product'
//core
import { AppState } from '@redux/store'
//consts
import PlacementLayout from '@components/Cms/PlacementLayout'
import mockedData from '@components/ProductImage/mocked-attachments'
import { BreadcrumbLayout } from '@components/breadcrumb'
import config from '@configs/config.base'
import { PRODUCT_SOLDOUT_STATUS, PRODUCT_TYPES_KEYS } from '@constants/product'
import { ICategory } from '@features/category/query'
import { orderItemsSelector, shipInfosSelector } from '@features/order/selector'
import useBreakpoints from '@hooks/useBreakpoints'
import { retrieveProductImages } from '@hooks/useProductImages/useProductImages'
import { IOrderItem } from '@typesApp/order'
import { sortImageByConf } from '@utils/attachmentsUtils'
import { getParsedOrderItems } from '@utils/order'
import { isAccessories, isCLAccessories, isContactLenses, isFrames, isOptical, isSun } from '@utils/product'
import {
  getClMaterialPatented,
  getProductType,
  getSoldOut,
  getClCorrectionType,
  getModelName,
} from '@utils/productAttributes'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import BrandIcon from '../../components/BrandIcon'
import { IPlacement } from '../../types/cmsPlacement/Placement'
import {
  PdpContainer,
  PdpLeftColumn,
  PdpRightColumn,
  ProductImagesMainSlider,
  SliderWrapper,
  StyledLeftColumnContainer,
  StyledSuggestionContainer,
  ProductDetailsListMobile,
  ProductDetailsListDesktop,
} from './ProductDetails.style'
//libs
import {
  isVMMVEnabledSelector,
  locationOriginSelector,
  pdpAccImageOrderSelector,
  pdpClImageOrderSelector,
  pdpFrameImageOrderSelector,
} from '@redux/selectors/site'
//utils
import { orderApi } from '@features/order/query'
import { useSite } from '@foundation/hooks/useSite'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { useTranslation } from 'next-i18next'
import ProductAttributeBadges from './components/ProductAttributeBadges/ProductAttributeBadges'
import ProductFullPageGallery from './components/ProductFullPageGallery'
import ProductImageNotAvailable from './components/ProductImageNotAvailable'
import ProductImageZoom, { ProductImageZoomProps } from './components/ProductImageZoom/ProductImageZoom'
import { useProductImageZoom } from './components/ProductImageZoom/ProductImageZoomContext'

import ProductImagesThumbnails from './components/ProductImagesThumbnails'
import { AccessoriesPdpRightColumn } from './components/ProductRightColumn/AccessoriesPdpRightColumn'
import { CLAccessoriesPdpRightColumn } from './components/ProductRightColumn/CLAccessoriesPdpRightColumn'
import { ContactLensesPdpRightColumn } from './components/ProductRightColumn/ContactLensesPdpRightColumn'
import { FramesPdpRightColumn } from './components/ProductRightColumn/FramesPdpRightColumn'
import ProductSoldoutBanner from './components/ProductSoldoutBanner'
import { ProductSoldoutContainer, StyledSoldOutBrandIconWrapper } from './components/ProductSoldoutBanner.style'
import SupplyBadges from './components/SupplyBadge/SupplyBadges'
import VirtualMirrorCTA from './components/VirtualMirrorCTA/VirtualMirrorCTA'
import {
  VirtualMirrorCTAContainerDesktop,
  VirtualMirrorCTAContainerMobile,
} from './components/VirtualMirrorCTA/VirtualMirrorCTA.style'
import { useAnnualSupply } from './hooks/useAnnualSupply'
import { useProductSoldOutState } from './useProductSoldOutState'
import useDataLayerExtendedEvent from '@hooks/useDataLayerExtendedEvent'
import { ProductNameCLSection } from './components/ProductNameCLSection'
import ProductPriceNew from './components/ProductPriceNew'
import { ProductDetailsList } from './components/ProductDetailsList'
import { SuggestedProducts } from '@components/common/components/SuggestedProducts'
import { THclProductRes, hclDTO } from '@utils/productNew'
import { PRODUCT_CAROUSEL_TEMPLATE_ID, PRODUCT_LIST_TEMPLATE_ID } from '@components/TrustPilot/constants'
import { styled } from '@mui/material'
import Container from '@components/UI/Container'
import { parseProductTypeForPrice } from '@components/common/UI/ProductPrice/utils/utils'
import dynamic from 'next/dynamic'
import { trackSingleProduct } from '@foundation/monetate/lib'
import PDPStickyBar from './components/PdpStickyBar/PDPStickyBar'
import { usePageType } from '@foundation/hooks/usePageType'
import { listenMonetateQ } from '@foundation/monetate/useMonetate'
import useProductPrice from '@hooks/useProductPrice'
import { replaceLocaleInUrl } from '@utils/locale'
import { ICMExternalChannel } from '@typesApp/cmsPlacement/CMExternalChannel'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'

const TrustpilotWidget = dynamic(() => import('@components/TrustPilot/TrustpilotWidget'), {
  ssr: false,
})

const StyledProductContainer = styled(Container, {
  name: 'ProductContainer',
  slot: 'MainContainer',
})({
  width: '100%',
})

type ProductDetailsProps = {
  partNumber: string
  pdpData: IProduct
  loading: boolean
  placements?: IPlacement[]
  onClusterProductClick?: (p: IProduct) => void
  breadcrumbs?: any[]
  categoryData?: ICategory[]
  isVmProduct: boolean
  suggestedProducts: AssociatedProduct[] | undefined
  currentProduct: IProduct
  isRoxable: boolean
  pageIdentifier: string
}

export const getRightColumnContentType = (productType: string): ProductType | string => {
  return isContactLenses(productType) || isAccessories(productType) || isCLAccessories(productType)
    ? productType
    : PRODUCT_TYPES_KEYS.FRAMES
}

const ProductImageZoomWithZoomContext = (props: ProductImageZoomProps) => {
  const { zoomModalOpen, setZoomModalOpen } = useProductImageZoom()

  return (
    <ProductImageZoom
      {...props}
      isvisible={zoomModalOpen}
      onCloseClick={() => {
        setZoomModalOpen(0)
      }}
    />
  )
}

const ProductImageSliderWithZoomContext = ({
  sortedImageByConf,
  currentProduct,
  fullPageGalleryVisible,
  setFullPageGalleryVisible,
  setCurrentSlide,
  currentSlide,
}: {
  currentProduct: IProduct
  sortedImageByConf: Attachment[]
  fullPageGalleryVisible: 1 | 0
  setFullPageGalleryVisible: (value: 1 | 0) => void
  setCurrentSlide: (value: number) => void
  currentSlide: number
}) => {
  const { zoomModalOpen, setZoomModalOpen } = useProductImageZoom()
  const { isDesktop } = useBreakpoints()
  return (
    <ProductImagesMainSlider
      images={sortedImageByConf}
      sliderImageProps={{
        srcsetmap: {
          300: '320w',
          400: '640w',
          600: '960w',
          700: '1280w',
          900: '1920w',
        },
        width: 600,
        usage: 'PDP',
        alt: currentProduct?.productAttributes['MODEL_NAME'],
      }}
      onImageClick={() => {
        !isDesktop && setFullPageGalleryVisible(fullPageGalleryVisible === 1 ? 0 : 1)
        isDesktop && setZoomModalOpen(zoomModalOpen === 1 ? 0 : 1)
      }}
      onSlideChange={index => {
        setCurrentSlide(index || 0)
      }}
      currentDisplayedSlide={currentSlide}
      slideIndex={currentSlide}
      pdpdataloading={false}
      shownavigation={!isDesktop}
    >
      <ProductAttributeBadges productAttributes={currentProduct.productAttributes}></ProductAttributeBadges>
    </ProductImagesMainSlider>
  )
}

const ProductDetails: React.FC<ProductDetailsProps> = ({
  partNumber,
  pdpData,
  onClusterProductClick,
  placements,
  breadcrumbs,
  categoryData,
  isVmProduct,
  suggestedProducts,
  currentProduct,
  isRoxable,
  pageIdentifier,
}) => {
  const { t } = useTranslation()
  const { locale, isLocaleDomain } = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)
  const content = useSelector((s: AppState) => s.cms.megaMenu?.contentMegaMenu)
  const itemCollection = content?.[0]?.placements?.[0]?.items?.[0] as ICMCollection | undefined
  const brandList = itemCollection?.teasableItems as ICMExternalChannel[] | undefined
  const [fullPageGalleryVisible, setFullPageGalleryVisible] = useState<1 | 0>(0)
  const { isDesktop, isTabletLandscape } = useBreakpoints()

  const pdpFrameImageOrder = useSelector(pdpFrameImageOrderSelector, shallowEqual)
  const { langCode } = useStoreIdentity()
  const { mySite } = useSite()
  const pageType = usePageType()

  const [currentSlide, setCurrentSlide] = useState<number>(0)
  const pdpAccImageOrder = useSelector(pdpAccImageOrderSelector, shallowEqual)
  const pdpClImageOrder = useSelector(pdpClImageOrderSelector, shallowEqual)
  const supplyData = useAnnualSupply(currentProduct)
  const isVMMVEnabled = useSelector(isVMMVEnabledSelector)
  const shipInfos = useSelector(shipInfosSelector)

  const currentImages = retrieveProductImages(currentProduct)
  const [productQuantity, setProductQuantity] = React.useState<string[]>(['1'])
  const [getShippingInfoForUpc] = orderApi.endpoints.getShippingInfoForUpc.useLazyQuery()

  const images: Attachment[] = config.useMockedAttachments !== 'false' ? mockedData.attachments : currentImages
  const ctaRef = useRef<HTMLDivElement>(null)
  const stickyBarCtaRef = useRef<HTMLDivElement>(null)
  const orderItems = useSelector(orderItemsSelector)
  const productType = getProductType(currentProduct)
  const query = useSearchParams()
  const monetateLoaded = listenMonetateQ()
  const areImagesAvailable = images && images.length > 0
  const areSuggestedAvailable = suggestedProducts && suggestedProducts?.length > 0
  const groupedOrderId = query.get('groupedOrderId')
  const rightColumnContentType = getRightColumnContentType(productType)
  const isFramePage = isSun(productType) || isOptical(productType) || isFrames(productType)

  const [showProductListInfoDescLast, setShowProductListInfoDescLast] = useState<boolean>(false)
  const { currentPrice: prices } = useProductPrice(currentProduct.x_price)

  const productInCart: IOrderItem | undefined =
    (!!orderItems && !!groupedOrderId
      ? getParsedOrderItems(orderItems)?.find(
          i => i.groupedOrderItemsId?.join('') === groupedOrderId || i.orderItemId === groupedOrderId
        ) || undefined
      : getParsedOrderItems(orderItems)?.find(
          i => i.partNumber === currentProduct.partNumber || i.partNumber === partNumber
        )) || undefined

  const { addToCartButtonFail, soldOutStatus, onlyFewPieces } = useProductSoldOutState({
    currentProductPartNumber: partNumber,
    product: currentProduct,
  })

  const isSoldout = soldOutStatus === PRODUCT_SOLDOUT_STATUS.SOLDOUT

  useEffect(() => {
    if (isSoldout) document.getElementsByTagName('main')[0].classList.remove('is-pdp')
    else document.getElementsByTagName('main')[0].classList.add('is-pdp')
  }, [isSoldout])

  useEffect(() => {
    if (['be', 'nl'].includes(mySite.country)) {
      setShowProductListInfoDescLast(true)
    }
  }, [mySite.country])

  const damDomain: string = mySite?.xStoreCfg?.['damDomain'] || config.defaultDamDomain
  useDataLayerExtendedEvent([
    {
      items: [
        {
          name: getModelName(currentProduct),
          type_of_lense: getClCorrectionType(currentProduct),
          material: getClMaterialPatented(currentProduct),
          image_url: damDomain + currentProduct?.attachments?.[0]?.attachmentAssetPathRaw || '',
          description: currentProduct?.longDescription || '',
        },
      ],
    },
  ])

  const isComingBackSoon = soldOutStatus === PRODUCT_SOLDOUT_STATUS.COMING_BACK_SOON

  const getSortArray = () => {
    switch (productType) {
      case 'accessories':
        return pdpAccImageOrder
      case 'contact-lenses':
        return pdpClImageOrder
      default:
        return pdpFrameImageOrder
    }
  }

  const sortArray = getSortArray()
  const sortedImageByConf: Attachment[] = sortImageByConf(images, sortArray)

  const rightColumnProps: ProductRightColumnProps = {
    pdpData,
    type: productType,
    partNumber,
    currentProduct,
    productItems: currentProduct?.items,
    cluster: pdpData?.cluster,
    onClusterProductClick: onClusterProductClick,
    productInCart: productInCart,
    addToCartButtonFail,
    isSoldout,
    isComingBackSoon,
    isRoxable,
    soldOutStatus,
    ctaRef,
    stickyBarCtaRef,
    placements,
    productQuantity,
    setProductQuantity,
    onlyFewPieces,
    shipInfos: shipInfos?.usableShippingMode[0]?.dedicatedShippingMode[0],
  }

  const getRightColumn = (productType: ProductType | string): ReactNode => {
    if (!productType) return null

    if (isContactLenses(productType)) {
      return <ContactLensesPdpRightColumn {...rightColumnProps} />
    }
    if (isAccessories(productType)) {
      return <AccessoriesPdpRightColumn {...rightColumnProps} />
    }
    if (isCLAccessories(productType)) {
      return <CLAccessoriesPdpRightColumn {...rightColumnProps} />
    }

    return <FramesPdpRightColumn {...rightColumnProps} />
  }

  const productDetailsList = (
    <ProductDetailsList
      currentProduct={currentProduct}
      type={soldOutStatus}
      showInfoDescLast={showProductListInfoDescLast}
    />
  )

  // TODO: Display sticky bar based on xStoreCfg
  // At the moment it's disabled (visible=false) already hence added feature flag to disable it completley for now
  const isStickyBarEnabled = false

  useEffect(() => {
    if (getSoldOut(currentProduct) !== PRODUCT_SOLDOUT_STATUS.SOLDOUT && !isSoldout) {
      getShippingInfoForUpc({ storeId: mySite.storeID, upc: partNumber })
    }
  }, [getShippingInfoForUpc, mySite.storeID, partNumber, currentProduct, isSoldout])

  const isVMEnabled = isVMMVEnabled && isFramePage && !isSoldout && isVmProduct
  const vmmvLabel = t('VirtualMirror.CTA.TryOn')
  const hclDTOs = (suggestedProducts as unknown as THclProductRes[]).map(hclDTO)

  useEffect(() => {
    if (currentProduct?.partNumber && pageType.pageType && monetateLoaded) {
      trackSingleProduct(currentProduct, partNumber, pageType.pageType)
    }
  }, [currentProduct?.partNumber, pageType.pageType, monetateLoaded])

  return (
    <>
      <StyledProductContainer id={pageIdentifier}>
        <BreadcrumbLayout
          cid=""
          className="pdp-container__breadcrumbs"
          searchTermText=""
          product={currentProduct}
          breadcrumbsList={breadcrumbs}
          langCode={langCode}
          isLocaleDomain={isLocaleDomain}
          locale={locale as string}
          locationOrigin={locationOrigin}
        />
        <PdpContainer>
          {areImagesAvailable && (
            <>
              <ProductFullPageGallery
                isvisible={fullPageGalleryVisible}
                images={sortedImageByConf}
                onCloseClick={() => setFullPageGalleryVisible(0)}
              />
              <ProductImageZoomWithZoomContext currentImageIndex={currentSlide} images={sortedImageByConf} />
            </>
          )}

          <PdpLeftColumn xs={'fullWidth'} md={5} lg={8}>
            <StyledLeftColumnContainer visible={1}>
              {currentProduct && brandList && (
                <ProductNameCLSection
                  currentProduct={currentProduct}
                  brandList={brandList}
                  brandPrefix={t('ProductDetails.Labels.BrandPrefix')}
                />
              )}
              {currentProduct && currentProduct.x_price && (
                <ProductPriceNew
                  isPDP={true}
                  isCL={true}
                  productType={parseProductTypeForPrice(productType)}
                  isCompact={true}
                  price={prices}
                  productQuantity={productQuantity}
                />
              )}
              <SliderWrapper>
                {!isSoldout && areImagesAvailable && (
                  <ProductImagesThumbnails
                    images={productType !== 'accessories' ? sortedImageByConf : []}
                    currentIndex={currentSlide}
                    brandName={currentProduct?.productAttributes['BRAND']}
                    onThumbnailClick={slideNumber => setCurrentSlide(slideNumber)}
                    thumbnailImageProps={{
                      preload: false,
                      usage: 'PDP',
                      width: 160,
                      alt: currentProduct && currentProduct?.productAttributes['MODEL_NAME'],
                    }}
                  />
                )}

                {!isSoldout ? (
                  areImagesAvailable ? (
                    <ProductImageSliderWithZoomContext
                      setFullPageGalleryVisible={setFullPageGalleryVisible}
                      fullPageGalleryVisible={fullPageGalleryVisible}
                      setCurrentSlide={setCurrentSlide}
                      currentSlide={currentSlide}
                      currentProduct={currentProduct}
                      sortedImageByConf={sortedImageByConf}
                    />
                  ) : (
                    <ProductImageNotAvailable />
                  )
                ) : (
                  <>
                    <StyledSoldOutBrandIconWrapper>
                      <BrandIcon name={currentProduct.productAttributes['BRAND_NAME']} width={80} />
                    </StyledSoldOutBrandIconWrapper>
                    <ProductSoldoutContainer>
                      <ProductSoldoutBanner parentCategory={categoryData} />
                    </ProductSoldoutContainer>
                  </>
                )}
              </SliderWrapper>

              {supplyData && isDesktop && <SupplyBadges supplyData={supplyData} />}
              {isVMEnabled && (
                <VirtualMirrorCTAContainerDesktop>
                  <VirtualMirrorCTA labelText={vmmvLabel} pdpData={pdpData} currentProduct={currentProduct} />
                </VirtualMirrorCTAContainerDesktop>
              )}
            </StyledLeftColumnContainer>

            <ProductDetailsListDesktop className="content-visibility">{productDetailsList}</ProductDetailsListDesktop>

            {isVMEnabled && (
              <VirtualMirrorCTAContainerMobile>
                <VirtualMirrorCTA labelText={vmmvLabel} pdpData={pdpData} currentProduct={currentProduct} />
              </VirtualMirrorCTAContainerMobile>
            )}
          </PdpLeftColumn>

          <PdpRightColumn
            enableFramesContentSorting={isFrames(rightColumnContentType ?? '')}
            xs="fullWidth"
            md={3}
            lg={4}
            visible
            isTabletLandscape={isTabletLandscape}
          >
            {getRightColumn(rightColumnContentType)}
          </PdpRightColumn>
        </PdpContainer>

        <ProductDetailsListMobile>{productDetailsList}</ProductDetailsListMobile>

        <PlacementLayout
          placements={placements ? placements : []}
          currentProduct={currentProduct}
          suggestedProducts={suggestedProducts}
          className="content-visibility"
        />

        {areSuggestedAvailable && (
          <StyledSuggestionContainer className="content-visibility">
            <SuggestedProducts products={hclDTOs} title={t('ProductDetails.Suggested.title')} lazy />
          </StyledSuggestionContainer>
        )}

        {isStickyBarEnabled && (
          <PDPStickyBar
            visible={false}
            loading={false}
            lazy={true}
            stickyBarCtaRef={stickyBarCtaRef}
            partNumber={partNumber}
            product={currentProduct}
            images={sortedImageByConf}
            currentIndex={currentSlide}
            soldOutState={soldOutStatus}
            addToCartButtonFail={addToCartButtonFail}
            isRoxable={isRoxable}
            onlyFewPieces={onlyFewPieces}
          />
        )}
      </StyledProductContainer>

      <Container>
        <TrustpilotWidget
          className="content-visibility"
          productSku={currentProduct?.partNumber}
          height={isDesktop ? '140px' : '492px'}
          widgetTemplateId={isDesktop ? PRODUCT_CAROUSEL_TEMPLATE_ID : PRODUCT_LIST_TEMPLATE_ID}
        />
      </Container>
    </>
  )
}

export default ProductDetails
