import React, { HTMLAttributeAnchorTarget, ReactNode } from 'react'
import Link from '../Link'

interface Node {
  type: 'element' | 'text' | 'comment'
  tagName?: string
  attributes?: Record<string, string>
  content?: string
  children?: Node[]
}

interface ConvertToNextLinkParams {
  html: string
  target?: HTMLAttributeAnchorTarget
}

const parseHTML = (html: string): Node[] => {
  const regex = /<!--[\s\S]*?-->|<\/?([a-z][a-z0-9]*)\b[^>]*>|[^<]+/gi
  const stack: Node[] = []
  const result: Node[] = []
  let currentParent: Node | null = null
  let lastIndex = 0

  html.replace(regex, (match, tagName, index) => {
    if (index > lastIndex) {
      const text = html.slice(lastIndex, index)
      if (text.trim()) {
        const textNode: Node = { type: 'text', content: text }
        if (currentParent) {
          currentParent.children?.push(textNode)
        } else {
          result.push(textNode)
        }
      }
    }

    if (match.startsWith('<!--')) {
      const commentNode: Node = { type: 'comment', content: match }
      if (currentParent) {
        currentParent.children?.push(commentNode)
      } else {
        result.push(commentNode)
      }
    } else if (match.startsWith('</')) {
      if (currentParent && currentParent.tagName === tagName) {
        const node = currentParent
        if (stack.length > 0) {
          currentParent = stack.pop() || null
        } else {
          currentParent = null
        }
        if (currentParent) {
          currentParent.children?.push(node)
        } else {
          result.push(node)
        }
      }
    } else if (match.startsWith('<')) {
      const selfClosing = /\/>$/.test(match)
      const attributes: Record<string, string> = {}
      match.replace(/([a-z0-9\-]+)(?:="([^"]*)")?/gi, (m, attrName, attrValue) => {
        if (attrName !== tagName) {
          attributes[attrName] = attrValue || ''
        }
        return m
      })

      const node: Node = { type: 'element', tagName, attributes, children: [] }
      if (!selfClosing) {
        if (currentParent) {
          stack.push(currentParent)
        }
        currentParent = node
      } else {
        if (currentParent) {
          currentParent.children?.push(node)
        } else {
          result.push(node)
        }
      }
    } else {
      const text = match
      if (text.trim()) {
        const textNode: Node = { type: 'text', content: text }
        if (currentParent) {
          currentParent.children?.push(textNode)
        } else {
          result.push(textNode)
        }
      }
    }

    lastIndex = index + match.length
    return match
  })

  while (stack.length > 0) {
    const node = stack.pop()!
    if (currentParent) {
      currentParent.children?.push(node)
    } else {
      result.push(node)
    }
    currentParent = node
  }

  return result
}

const convertToNextLink = ({ html, target }: ConvertToNextLinkParams): ReactNode[] => {
  const nodes = parseHTML(html)

  const convertNodeToElement = (node: Node, index: number): ReactNode => {
    if (node.type === 'element') {
      if (node.tagName === 'a' && node.attributes?.['data-show'] === 'replace') {
        const href = node.attributes.href || ''
        const props = { ...node.attributes }
        delete props.href

        return (
          <Link key={index} href={href} target={target}>
            {node.children?.map((child, i) => convertNodeToElement(child, i))}
          </Link>
        )
      } else {
        const Tag = node.tagName as keyof JSX.IntrinsicElements
        const props = node.attributes || {}
        return (
          <Tag key={index} {...props}>
            {node.children?.map((child, i) => convertNodeToElement(child, i))}
          </Tag>
        )
      }
    } else if (node.type === 'text') {
      return node.content
    }
    return null
  }

  return nodes.map((node, index) => convertNodeToElement(node, index))
}

export default convertToNextLink
