import { ConditionalWrapper } from '@components/common/Media/ConditionalWrapper'
import Log from '@services/Log'
import { ModulesConfigProps } from '@typesApp/cms'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
import { AssociatedProduct, IProduct, IServerProduct } from '@typesApp/product'
import { isCMCollection, isCMPlaceholder, isCMProductList, isCMQueryList, isICMHtml } from '@typesApp/teaser'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import {
  isAccessoriesProduct,
  isCLAccessoriesProduct,
  isContactLensesProduct,
  isElectronicsProduct,
} from '@utils/product'
import { THclProductRes } from '@utils/productNew'
import dynamic from 'next/dynamic'
import { useSearchParams } from 'next/navigation'
import React from 'react'
import CmsPlacementContainer from '../CmsComponents/CmsPlacementContainer'
import { StyledPlacementsWrapper } from './PlacementsSwitch.style'
import clsx from 'clsx'
const WallOfBrands = dynamic(() => import('@components/common/components/WallOfBrands'))
const SizeGuide = dynamic(() => import('@pages_views/ProductDetails/components/SizeGuide/SizeGuide'))
const TextModule = dynamic(() => import('../CmsPlacement/CmsBanner/TextModule'))
const ComboMiniSlider = dynamic(() => import('@components/common/components/ComboMiniSlider'))
const SubscriptionForm = dynamic(() => import('../CmsComponents/CmsMutuelleSubscriptionForm'), { ssr: false })

// NEW COMPONENTS
const SquareBoards = dynamic(() => import('@components/common/components/SquareBoards'))
const FullWidthBanner = dynamic(() => import('@components/common/components/FullWidthBanner'))
const LandscapeBanner = dynamic(() => import('@components/common/components/LandscapeBanner'))
const SquatBanner = dynamic(() => import('@components/common/components/SquatBanner'))
const TopPageBanner = dynamic(() => import('@components/common/components/TopPageBanner'))
const GridOfBoards = dynamic(() => import('@components/common/components/GridOfBoards'))
const GridOfProducts = dynamic(() => import('@components/common/components/GridOfProducts'))
const CmsProducts = dynamic(() => import('@components/common/components/CmsProducts'))
const CmsYouMayAlsoLike = dynamic(() => import('@components/common/components/CmsYouMayAlsoLike'))
const BoxAndProducts = dynamic(() => import('@components/common/components/BoxAndProducts'))
const Faqs = dynamic(() => import('../CmsPlacement/CmsBanner/Faqs'))
const BoardWithFieldsBanner = dynamic(() => import('@components/common/components/BoardWithFieldsBanner'))
const CmsPlainSlider = dynamic(() => import('@components/common/components/CmsPlainSlider'))
const GridOfCards = dynamic(() => import('@components/common/components/GridOfCards'))
const SocialVideo = dynamic(() => import('@components/common/components/SocialVideo'))
const BoxBanner = dynamic(() => import('@components/common/components/BoxBanner'))
const BannerWithSideFields = dynamic(() => import('@components/common/components/BannerWithSideFields'))
const AnchorButtons = dynamic(() => import('@components/common/components/AnchorButtons'))
const QueryList = dynamic(() => import('@components/common/components/QueryList'))
const TrustPilotReview = dynamic(() => import('@components/Cms/CmsComponents/TrustPilotReview'), {
  ssr: false,
})

interface PlacementsSwitchProps {
  placements?: IPlacement[]
  activePlacements?: IViewType[]
  //loading?: boolean
  currentProduct?: IProduct
  suggestedProducts?: AssociatedProduct[]
  isPLP: boolean
  contentVisibilityStart?: number
}

const isPlacementVisible = (
  activePlacements: PlacementsSwitchProps['activePlacements'],
  placementViewtype: IViewType
): boolean => {
  return activePlacements?.includes(placementViewtype) || false
}

const getCmsModulesByPlacement = (
  activePlacements,
  placement: IPlacement,
  currentProduct,
  suggestedProducts: IServerProduct[],
  teaserIndex: number,
  customerSegments: string[]
): ModulesConfigProps[] | null => {
  const reflect: boolean = placement.placementReflect || false
  const placementCenter = placement.placementCenter || false
  const isLazy = !['main_placement_1'].includes(placement.name)

  try {
    switch (placement.viewtype) {
      case 'full-width-banner':
        return [
          {
            banner: () => (
              <FullWidthBanner
                placement={placement}
                customerSegments={customerSegments}
                teaserIndex={teaserIndex}
                lazy={isLazy}
              />
            ),
            bannerPath: '../CmsPlacement/CmsBanner/FullWidthBanner',
            bannerProps: {
              placement,
              teaserIndex,
            },
          },
        ]
      case 'grid-of-products':
        return [
          {
            banner: () => <GridOfProducts placement={placement} lazy={isLazy} />,
          },
        ]
      case 'dcw-products':
        return [
          {
            banner: () => <CmsProducts placement={placement} teaserIndex={teaserIndex} lazy={isLazy} />,
          },
        ]
      case 'plain-slider':
        return [
          {
            banner: () => <CmsPlainSlider placement={placement} customerSegments={customerSegments} lazy={isLazy} />,
          },
        ]
      case 'combo-mini-slider-plus-box-all-fields':
        return [
          {
            banner: () => (
              <ComboMiniSlider
                reflect={reflect}
                placement={placement}
                customerSegments={customerSegments}
                lazy={isLazy}
              />
            ),
          },
        ]
      case 'wall-of-brands':
        return [
          {
            banner: () => <WallOfBrands placement={placement} />,
          },
        ]
      case 'faqs':
        return [
          {
            banner: () => <Faqs placement={placement} />,
          },
        ]
      case 'landscape-banner':
        return [
          {
            banner: () => (
              <LandscapeBanner
                placement={placement}
                customerSegments={customerSegments}
                teaserIndex={teaserIndex}
                lazy={isLazy}
              />
            ),
          },
        ]
      case 'top-page-banner':
        return [
          {
            banner: () => (
              <TopPageBanner
                placement={placement}
                customerSegments={customerSegments}
                teaserIndex={teaserIndex}
                lazy={isLazy}
              />
            ),
          },
        ]
      case 'box-with-margin':
        return [
          {
            banner: () => (
              <BoxBanner
                placement={placement}
                teaserIndex={teaserIndex}
                lazy={isLazy}
                customerSegments={customerSegments}
                withMargin
              />
            ),
          },
        ]
      case 'boards-with-fields-below':
        return [
          {
            banner: () => (
              <BoardWithFieldsBanner
                placement={placement}
                placementCenter={placementCenter}
                teaserIndex={teaserIndex}
                lazy={isLazy}
                customerSegments={customerSegments}
              />
            ),
          },
        ]
      case 'grid-of-boards-two-columns':
        return [
          {
            banner: () => (
              <GridOfBoards
                columnAmount={2}
                placement={placement}
                placementCenter={placementCenter}
                teaserIndex={teaserIndex}
              />
            ),
          },
        ]
      case 'grid-of-boards-three-columns':
        return [
          {
            banner: () => (
              <GridOfBoards
                columnAmount={3}
                placement={placement}
                placementCenter={placementCenter}
                teaserIndex={teaserIndex}
              />
            ),
          },
        ]
      case 'squat-banner':
        return [
          {
            banner: () => (
              <SquatBanner
                placement={placement}
                customerSegments={customerSegments}
                teaserIndex={teaserIndex}
                lazy={isLazy}
              />
            ),
          },
        ]
      case 'square-boards-with-split':
        return [
          {
            banner: () => <SquareBoards placement={placement} customerSegments={customerSegments} lazy={isLazy} />,
          },
        ]
      case 'square-boards-without-split':
        return [
          {
            banner: () => (
              <SquareBoards placement={placement} customerSegments={customerSegments} lazy={isLazy} withoutSplit />
            ),
          },
        ]
      case 'text-module':
        return [
          {
            banner: () => <TextModule placement={placement} teaserIndex={teaserIndex} />,
          },
        ]
      case 'box-and-2-products':
      case 'box-and-4-products':
        return [
          {
            banner: () => (
              <BoxAndProducts
                placement={placement}
                customerSegments={customerSegments}
                teaserIndex={teaserIndex}
                lazy={isLazy}
              />
            ),
          },
        ]
      case 'grid-of-cards':
        return [
          {
            banner: () => (
              <GridOfCards
                placement={placement}
                customerSegments={customerSegments}
                teaserIndex={teaserIndex}
                lazy={isLazy}
              />
            ),
          },
        ]
      case 'm-social-video':
        return [
          {
            banner: () => (
              <SocialVideo
                placement={placement}
                customerSegments={customerSegments}
                teaserIndex={teaserIndex}
                lazy={isLazy}
              />
            ),
          },
        ]
      case 'single-banner-with-side-fields':
        return [
          {
            banner: () => <BannerWithSideFields placement={placement} />,
          },
        ]
      case 'anchor-button':
        return [
          {
            banner: () => <AnchorButtons placement={placement} />,
          },
        ]
      case 'default':
        if (placement.items.length === 0) {
          return null
        }

        const collection = placement.items?.filter(item => item !== null).find(isCMCollection)

        const placeholder = placement.items?.filter(item => item !== null).find(isCMPlaceholder)

        const productList = placement.items?.filter(item => item !== null).find(isCMProductList)

        const queryList = placement.items?.filter(item => item !== null).find(isCMQueryList)

        const cmhtml = placement.items?.filter(item => item !== null).find(isICMHtml)

        const firstItem = collection || productList || queryList || placeholder || cmhtml || null

        if (firstItem !== null && firstItem.type === 'CMHTML') {
          const html = cmhtml?.html || ''

          return [
            {
              banner: () => (
                <>
                  <div dangerouslySetInnerHTML={{ __html: html }}></div>
                </>
              ),
            },
          ]
        }

        if (firstItem !== null && isPlacementVisible(activePlacements, firstItem?.viewtype)) {
          const firstItem = collection! || productList! || queryList! || placeholder!
          switch (firstItem.viewtype) {
            case 'text-module':
              return [
                {
                  banner: () => <TextModule placement={firstItem} teaserIndex={teaserIndex} />,
                },
              ]
            case 'full-width-banner':
              return [
                {
                  banner: () => (
                    <FullWidthBanner
                      placement={firstItem}
                      customerSegments={customerSegments}
                      teaserIndex={teaserIndex}
                      lazy={isLazy}
                    />
                  ),
                },
              ]
            case 'plain-slider':
              return [
                {
                  banner: () => <CmsPlainSlider placement={firstItem} customerSegments={customerSegments} />,
                },
              ]
            case 'combo-mini-slider-plus-box-all-fields':
              return [
                {
                  banner: () => (
                    <ComboMiniSlider reflect={reflect} placement={firstItem} customerSegments={customerSegments} />
                  ),
                },
              ]
            case 'grid-of-products':
              return [
                {
                  banner: () => <GridOfProducts placement={firstItem} lazy={isLazy} />,
                },
              ]
            case 'dcw-products':
              return [
                {
                  banner: () => <CmsProducts placement={firstItem} teaserIndex={teaserIndex} lazy={isLazy} />,
                },
              ]
            case 'landscape-banner':
              return [
                {
                  banner: () => (
                    <LandscapeBanner
                      placement={firstItem}
                      customerSegments={customerSegments}
                      teaserIndex={teaserIndex}
                      lazy={isLazy}
                    />
                  ),
                },
              ]
            case 'top-page-banner':
              return [
                {
                  banner: () => (
                    <TopPageBanner
                      placement={firstItem}
                      customerSegments={customerSegments}
                      teaserIndex={teaserIndex}
                      lazy={isLazy}
                    />
                  ),
                },
              ]
            case 'squat-banner':
              return [
                {
                  banner: () => (
                    <SquatBanner
                      placement={firstItem}
                      customerSegments={customerSegments}
                      teaserIndex={teaserIndex}
                      lazy={isLazy}
                    />
                  ),
                },
              ]
            case 'box-with-margin':
              return [
                {
                  banner: () => (
                    <BoxBanner
                      placement={firstItem}
                      teaserIndex={teaserIndex}
                      lazy={isLazy}
                      customerSegments={customerSegments}
                      withMargin
                    />
                  ),
                },
              ]
            case 'boards-with-fields-below':
              return [
                {
                  banner: () => (
                    <BoardWithFieldsBanner
                      placement={firstItem}
                      placementCenter={placementCenter}
                      teaserIndex={teaserIndex}
                      lazy={isLazy}
                      customerSegments={customerSegments}
                    />
                  ),
                },
              ]
            case 'square-boards-with-split':
              return [
                {
                  banner: () => <SquareBoards placement={firstItem} customerSegments={customerSegments} />,
                },
              ]
            case 'square-boards-without-split':
              return [
                {
                  banner: () => <SquareBoards placement={firstItem} customerSegments={customerSegments} withoutSplit />,
                },
              ]
            case 'grid-of-boards-two-columns':
              return [
                {
                  banner: () => (
                    <GridOfBoards
                      columnAmount={2}
                      placement={firstItem}
                      placementCenter={placementCenter}
                      teaserIndex={teaserIndex}
                    />
                  ),
                },
              ]
            case 'grid-of-boards-three-columns':
              return [
                {
                  banner: () => (
                    <GridOfBoards
                      columnAmount={3}
                      placement={firstItem}
                      placementCenter={placementCenter}
                      teaserIndex={teaserIndex}
                    />
                  ),
                },
              ]
            case 'query-list':
              return [
                {
                  banner: () => <QueryList placement={firstItem} />,
                },
              ]
            case 'you-may-also-like':
              if (suggestedProducts.length === 0) return null
              return [
                {
                  banner: () => <CmsYouMayAlsoLike products={suggestedProducts as unknown as THclProductRes[]} />,
                },
              ]
            case 'size-guide':
              return (
                currentProduct &&
                !isContactLensesProduct(currentProduct) &&
                !isCLAccessoriesProduct(currentProduct) &&
                !isAccessoriesProduct(currentProduct) &&
                !isElectronicsProduct(currentProduct) && [
                  {
                    banner: () => <SizeGuide currentProduct={currentProduct} />,
                  },
                ]
              )
            case 'wall-of-brands':
              return [
                {
                  banner: () => <WallOfBrands placement={firstItem} />,
                },
              ]
            case 'box-and-2-products':
            case 'box-and-4-products':
              return [
                {
                  banner: () => (
                    <BoxAndProducts
                      placement={firstItem}
                      customerSegments={customerSegments}
                      teaserIndex={teaserIndex}
                    />
                  ),
                },
              ]
            case 'grid-of-cards':
              return [
                {
                  banner: () => (
                    <GridOfCards
                      placement={firstItem}
                      customerSegments={customerSegments}
                      teaserIndex={teaserIndex}
                      lazy={isLazy}
                    />
                  ),
                },
              ]
            case 'm-social-video':
              return [
                {
                  banner: () => (
                    <SocialVideo
                      placement={firstItem}
                      customerSegments={customerSegments}
                      teaserIndex={teaserIndex}
                      lazy={isLazy}
                    />
                  ),
                },
              ]
            case 'anchor-button':
              return [
                {
                  banner: () => <AnchorButtons placement={firstItem} />,
                },
              ]
            case 'registration-form-module-1':
              return [
                {
                  banner: () => <SubscriptionForm />,
                },
              ]
            case 'trust-pilot':
              return [
                {
                  banner: () => <TrustPilotReview />,
                },
              ]
            default:
              return null
          }
        } else {
          return null
        }
      default:
        return null
    }
  } catch (e: any) {
    Log.error('ERROR IN GETTING CMS PLACEMENTS', e)
    return null
  }
}

const PlacementsSwitch: React.FC<PlacementsSwitchProps> = ({
  activePlacements = [],
  currentProduct,
  placements,
  suggestedProducts,
  isPLP,
  contentVisibilityStart = 1,
}) => {
  const query = useSearchParams()
  const placementsToShow: IViewType[] = [...activePlacements, 'default']

  const activePlacementsList =
    placements
      ?.filter(
        placement =>
          isPlacementVisible(placementsToShow, placement.viewtype) &&
          !['header_promo_bar', 'footer_before_footer', 'footer_contact'].includes(placement?.name!)
      )
      .filter(placement => {
        return !isPLP ? placement : !query.get('offset') ? placement : placement?.name !== 'PLP_placement_1'
      }) || []

  const customerSegments = useCustomerSegmentsUtil()

  return (
    <StyledPlacementsWrapper>
      <>
        {activePlacementsList?.map((placement, i) => {
          const banners = getCmsModulesByPlacement(
            placementsToShow,
            placement,
            currentProduct,
            suggestedProducts!,
            i + 1,
            customerSegments
          )
          return banners !== null ? (
            <ConditionalWrapper
              key={`${placement.name}-${i}`}
              condition={i > contentVisibilityStart}
              className={clsx({
                'content-visibility': i > 2,
              })}
            >
              <CmsPlacementContainer teaserIndex={i + 1} placement={placement} banners={banners} />
            </ConditionalWrapper>
          ) : null
        })}
      </>
    </StyledPlacementsWrapper>
  )
}

export default PlacementsSwitch
