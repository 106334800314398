export const CAROUSEL_TEMPLATE_ID = '53aa8912dec7e10d38f59f36'
export const LIST_TEMPLATE_ID = '539ad60defb9600b94d7df2c'
export const MICRO_TEMPLATE_ID = '5419b732fbfb950b10de65e5'
export const PRODUCT_CAROUSEL_TEMPLATE_ID = '60f537b5b0f1639de1fe048c'
export const PRODUCT_LIST_TEMPLATE_ID = '544a426205dc0a09088833c6'
export const MICRO_WIDGET_STYLE_HEIGHT = '19px'
export const DEFAULT_STORE_LOCALE = 'en_IE'
export const LOCALE_STORE_MAPPING = {
  en_ie: {
    businessUnitId: '5440c61500006400057af93d',
    reviewLanguage: 'en',
    siteURL: 'https://www.trustpilot.com/review/visiondirect.ie',
  },
  en_uk: {
    businessUnitId: '487b77ca000064000502eb93',
    reviewLanguage: 'en',
    siteURL: 'https://www.trustpilot.com/review/visiondirect.co.uk',
  },
  fr_be: {
    businessUnitId: '5450ad8100006400057b35a0',
    reviewLanguage: 'fr',
    siteURL: 'https://www.trustpilot.com/review/visiondirect.be',
  },
  nl_be: {
    businessUnitId: '5450ad8100006400057b35a0',
    reviewLanguage: 'nl',
    siteURL: 'https://www.trustpilot.com/review/visiondirect.be',
  },
  nl_nl: {
    businessUnitId: '5432b81f00006400057ac267',
    reviewLanguage: 'nl',
    siteURL: 'https://www.trustpilot.com/review/visiondirect.nl',
  },
  de_de: {
    businessUnitId: '5450ad8d00006400057b35a2',
    reviewLanguage: 'de',
    siteURL: 'https://www.trustpilot.com/review/visiondirect.de',
  },
  fr_fr: {
    businessUnitId: '5440c69600006400057af942',
    reviewLanguage: 'fr',
    siteURL: 'https://www.trustpilot.com/review/visiondirect.fr',
  },
  it_it: {
    businessUnitId: '5440c60e00006400057af93c',
    reviewLanguage: 'it',
    siteURL: 'https://www.trustpilot.com/review/visiondirect.it',
  },
  es_es: {
    businessUnitId: '5440c5fa00006400057af93a',
    reviewLanguage: 'es',
    siteURL: 'https://www.trustpilot.com/review/visiondirect.es',
  },
  en_ww: {
    businessUnitId: '5440c60e00006400057af93c',
    reviewLanguage: 'it',
    siteURL: 'https://www.trustpilot.com/review/visiondirect.it',
  },
}
