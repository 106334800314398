import {
  styled,
  Breadcrumbs as MuiBreadcrumbs,
  Typography as MuiTypography,
  breadcrumbsClasses as MuiBreadcrumbsClasses,
} from '@mui/material'
import { StyledLink } from '../Link/Link.style'

export const StyledBreadcrumbs = styled(MuiBreadcrumbs, {
  name: 'Breadcrumbs',
})(() => ({
  [`&.${MuiBreadcrumbsClasses.root}`]: {
    position: 'relative',
    zIndex: 1,
  },
}))

export const StyledTypography = styled(MuiTypography)(({ theme }) => ({
  [`&.${MuiBreadcrumbsClasses.root}`]: {
    color: theme.palette.text.primary,
  },
}))

export const StyledBreadcrumbLink = styled(StyledLink, {
  name: 'BreadcrumbsList',
})(({ theme }) => ({
  textDecoration: 'none',
  ...theme.typography.body2,
}))
