import { IProduct } from '@typesApp/product'
import { useTranslation } from 'next-i18next'
import { Typography } from '@mui/material'
import { StyledBrand } from './ProductNameCLSection.style'
import Link from '@components/UI/Link'
import { ICMExternalChannel } from '@typesApp/cmsPlacement/CMExternalChannel'

interface IProductDetailsList {
  currentProduct: IProduct
  brandList: ICMExternalChannel[] | undefined
  brandPrefix?: string
}

const getBrandLink = (
  currentProduct: IProduct,
  brandList: ICMExternalChannel[] | undefined,
  brandPlaceholder: string
) => {
  const brandName = currentProduct?.productAttributes['CL_BRAND_FAMILY'] || brandPlaceholder
  const url = brandList?.find(list => list?.title?.toLowerCase() === brandName?.toLowerCase())?.formattedUrl
  return url ? <Link href={url}>{brandName}</Link> : <span>{brandName}</span>
}

export const ProductNameCLSection = ({ currentProduct, brandList, brandPrefix }: IProductDetailsList) => {
  const { t } = useTranslation()
  const brandLink = getBrandLink(currentProduct, brandList, t('ProductDetails.Labels.BrandName'))

  return (
    currentProduct && (
      <>
        <Typography variant="h4">{currentProduct?.productAttributes['MODEL_NAME']}</Typography>
        <StyledBrand variant="body1">
          {brandPrefix} {brandLink}
        </StyledBrand>
      </>
    )
  )
}
