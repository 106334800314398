import styled from '@mui/material/styles/styled'

export const ProductDetailsListWrapper = styled('div', {
  name: 'ProductDetailsList',
  slot: 'Wrapper',
})(({ theme }) => ({
  padding: theme.spacing(4, 0),
  marginTop: theme.spacing(10),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(9.25, 0),
  },
}))

export const ProductDetailsListHeader = styled('div', {
  name: 'ProductDetailsList',
  slot: 'Header',
})(() => ({
  display: 'flex',
  alignSelf: 'stretch',
  flexGrow: 0,
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: 16,
  padding: 0,
}))

export const HeaderTitle = styled('span', {
  name: 'ProductDetailsList',
  slot: 'HeaderTitle',
})(({ theme }) => ({
  flexGrow: 0,
  fontFamily: 'inherit',
  fontSize: 20,
  fontWeight: 600,
  lineHeight: 1.5,
  color: theme.palette.text.dark.primary,
  textTransform: 'uppercase',
}))

export const ProductDetailsListContainer = styled('div', {
  name: 'ProductDetailsList',
  slot: 'Container',
})(({ theme }) => ({
  marginTop: theme.spacing(6),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
}))

export const ProductDetailsAttributeContainer = styled('div', {
  name: 'ProductDetails',
  slot: 'AttributeContainer',
})(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  flexDirection: 'column',
  gap: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: '0.5fr 1fr',
  },
}))

export const ProductDetailsAttributeValue = styled('span', {
  name: 'ProductDetails',
  slot: 'AttributeValue',
})(({ theme }) => ({
  flexGrow: 0,
  fontFamily: 'inherit',
  fontSize: 14,
  fontWeight: 'normal',
  lineHeight: 1.43,
  textAlign: 'left',
  color: theme.palette.text.dark.primary,
}))

export const ProductDetailsAttributeName = styled(ProductDetailsAttributeValue, {
  name: 'ProductDetails',
  slot: 'AttributeName',
})({
  fontWeight: 600,
  textTransform: 'uppercase',
})

export const ProductDetailsDescriptionContainer = styled('div', {
  name: 'ProductDetails',
  slot: 'DescriptionContainer',
})(({ theme }) => ({
  marginTop: theme.spacing(10),
  flexGrow: 0,
  padding: '0 1px 0 0',
  fontFamily: 'inherit',
  fontSize: 14,
  fontWeight: 'normal',
  lineHeight: 1.43,
  textAlign: 'left',
  color: theme.palette.text.dark.primary,
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(20),
  },
  a: {
    textDecoration: 'underline',
  },

  ul: {
    marginBottom: theme.spacing(7),
  },

  p: {
    marginBottom: theme.spacing(7),
  },
}))
