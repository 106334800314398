import { Typography } from '@mui/material'
import styled from '@mui/material/styles/styled'

export const ProductNameWrapper = styled('div', {
  name: 'ProductNameCLSection',
  slot: 'Wrapper',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

export const StyledBrand = styled(Typography)(() => ({
  margin: 0,
}))
